<template>
  <div>
    <H5header :type="2"></H5header>
    <div class="top">
      <div class="titlebox">
        <div class="xian"></div>
        <div class="title">新增常用报名人</div>
      </div>
    </div>
    <div class="inputbox">
      <img src="../../assets/image/user.png" alt="" style="width: 13PX;height: 15PX;">
      <p>姓名</p>
      <span style="margin-right: 23px;">*</span>
      <el-input placeholder="请输入" v-model="info.name"></el-input>
    </div>
    <div class="inputbox">
      <img src="../../assets/image/gender.png" alt="" style="width: 13PX;height: 15PX;">
      <p>性别</p>
      <span style="margin-right: 28PX;">*</span>
      <a-select option-label-prop="label" placeholder="请选择" v-model="info.sex" allowClear style="width: 180PX;">
        <a-select-option v-for="items in gender" :key="items.value" :value="items.value" :label="items.label">
          <a-icon type="check" v-if="items.value === info.sex" />
          {{ items.label }}
        </a-select-option>
      </a-select>
      <img src="../../assets/image/more.png" style="margin-right: 12PX;z-index: 99;width: 6PX;height: 11PX;">
    </div>
    <div class="inputbox">
      <img src="../../assets/image/ID.png" alt="" style="width: 15PX;height: 15PX;">
      <p>证件类型</p>
      <span style="margin-right: 3PX;">*</span>
      <a-select option-label-prop="label" placeholder="请选择" v-model="info.certificateType" allowClear
        style="width: 180PX;">
        <a-select-option v-for="items in idCardType" :key="items.itemValue" :value="items.itemValue"
          :label="items.itemText">
          <a-icon type="check" v-if="items.itemValue === info.certificateType" />
          {{ items.itemText }}
        </a-select-option>
      </a-select>
      <img src="../../assets/image/more.png" style="margin-right: 12PX;z-index: 99;width: 6PX;height: 11PX;">
    </div>
    <div class="inputbox">
      <img src="../../assets/image/ID1.png" alt="" style="width: 15PX;height: 15PX;">
      <p>证件号码</p>
      <span>*</span>
      <el-input placeholder="请输入" v-model="info.idCard" @blur="getcardnum" @input="formatIdCard"
        maxlength="30"></el-input>
    </div>
    <div class="inputbox">
      <img src="../../assets/image/dateofbirth.png" alt="" style="width: 13PX;height: 15PX;">
      <p>出生日期</p>
      <span>*</span>
      <el-date-picker v-model="info.birthday" type="date" placeholder="请选择" format="yyyy-MM-dd"
        value-format="yyyy-MM-dd" style="width: 198px;">
      </el-date-picker>
      <img src="../../assets/image/more.png"
        style="margin-right: 12PX;z-index: 99;width: 6PX;height: 11PX;margin-left: 0;">
    </div>
    <div class="inputbox">
      <img src="../../assets/image/tel.png" alt="" style="width: 13PX;height: 15PX;">
      <p style="margin-left: 14PX;">手机号</p>
      <span style="margin-right: 10PX;">*</span>
      <el-input placeholder="请输入" v-model="info.phone"></el-input>
    </div>

    <div class="button">
      <div class="save" @click="getaddUserContact">提交</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Meta from 'vue-meta'
Vue.use(Meta)
import H5header from "./components/header2.vue"
export default {
  components: { H5header },
  props: {},
  data() {
    return {
      idCardType: [],
      gender: [{
        label: '男',
        value: 1
      },
      {
        label: '女',
        value: 2
      }],
      info: {
        name: '',
        phone: '',
        sex: '',
        certificateType: '01',
        idCard: '',
        birthday: '',
        userId: ''
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    getIdCardType() {
      this.$api.idCardType({ dictValue: '其他' }).then(res => {
        this.idCardType = res.data.data.result
      })
    },
    formatIdCard() {
      this.info.idCard = this.info.idCard.replace(/x/gi, 'X')
    },
    getcardnum() {
      if (this.info.certificateType == '01') {
        this.info.birthday = this.getBirthdayAndGender(this.info.idCard).birthday
        this.info.sex = this.getBirthdayAndGender(this.info.idCard).gender
      }
    },
    // 验证身份证是否正确
    validateIdCard(idCard) {
      // 校验身份证号码长度
      if (idCard.length !== 15 && idCard.length !== 18) {
        return false
      }

      // 校验前面的数字部分
      const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
      if (!pattern.test(idCard)) {
        return false
      }

      // 统一转换为 18 位身份证号码
      let idCard18 = idCard;
      if (idCard.length === 15) {
        idCard18 = this.convertTo18IdCard(idCard);
      }

      // 校验最后一位校验位
      const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
      const checkSum = idCard18.slice(0, 17).split('').reduce((sum, digit, index) => {
        return sum + parseInt(digit) * factors[index];
      }, 0) % 11;
      const checkCode = '10X98765432'; // 校验码映射
      if (idCard18[17] !== checkCode[checkSum]) {
        return false
      }

      // 验证通过
      return true
    },
    // 验证身份证 出生日期 性别 
    getBirthdayAndGender(idCard) {
      // 校验身份证号码长度
      if (idCard.length !== 15 && idCard.length !== 18) {
        return null;
      }

      // 校验前面的数字部分
      const pattern = idCard.length === 15 ? /^\d{15}$/ : /^\d{17}[\dXx]$/;
      if (!pattern.test(idCard)) {
        return null;
      }

      // 统一转换为 18 位身份证号码
      if (idCard.length === 15) {
        idCard = this.convertTo18IdCard(idCard);
      }
      // 校验最后一位校验位
      const factors = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
      const checkSum = idCard.slice(0, 17).split('').reduce((sum, digit, index) => {
        return sum + parseInt(digit) * factors[index];
      }, 0) % 11;
      const checkCode = '10X98765432'; // 校验码映射
      if (idCard[17] !== checkCode[checkSum]) {
        return null;
      }

      // 提取出生日期
      const birthDate = idCard.slice(6, 14);
      if (!/^\d{8}$/.test(birthDate)) {
        return null;
      }
      const year = parseInt(birthDate.slice(0, 4));
      const month = parseInt(birthDate.slice(4, 6));
      const day = parseInt(birthDate.slice(6, 8));

      // 提取性别
      const genderCode = parseInt(idCard[16]);
      const gender = genderCode % 2 === 0 ? 2 : 1;

      // 返回生日和性别信息
      return {
        birthday: `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`,
        gender: gender
      };
    },
    // 转换为 18 位身份证号码
    convertTo18IdCard(idCard) {
      if (idCard.length === 18) {
        return idCard;
      }
      const century = new Date().getFullYear() % 100;
      const year = parseInt(idCard.slice(6, 8));
      let prefix = '';

      if (year <= century) {
        prefix = '20';
      } else {
        prefix = '19';
      }
      return idCard.slice(0, 6) + prefix + idCard.slice(6);
    },
    getaddUserContact() {
      const regex = /^1[3456789]\d{9}$/
      this.info.userId = this.$store.state.userid

      if (this.$verification(this.info.name)) {
        return this.$message.error('请输入姓名')
      }
      if (this.$verification(this.info.sex)) {
        return this.$message.error('请选择性别')
      }
      if (this.$verification(this.info.certificateType)) {
        return this.$message.error('请选择证件类型')
      }
      if (this.$verification(this.info.idCard)) {
        return this.$message.error('请输入证件号码')
      }
      if (this.info.certificateType == '01') {
        if (!this.validateIdCard(this.info.idCard)) {
          return this.$message.error('证件号码格式错误')
        }
        if (this.getBirthdayAndGender(this.info.idCard).birthday != this.info.birthday) {
          return this.$message.error('出生日期与身份证上不符合')
        }
        if (this.getBirthdayAndGender(this.info.idCard).gender != this.info.sex) {
          return this.$message.error('性别与身份证实际不符合')
        }
      }
      if (this.$verification(this.info.birthday)) {
        return this.$message.error('请输入选择出生日期')
      }
      if (this.$verification(this.info.phone)) {
        return this.$message.error('请输入手机号')
      }
      if (!regex.test(this.info.phone)) {
        return this.$message.error('手机号码格式错误')
      }

      if (this.$route.query.index == 1) {
        this.$api.addUserContact(this.info).then(res => {
          this.$message.success('添加成功')
          this.handleCloses()
        })
      } else {
        this.$api.saveUserContact(this.info).then(res => {
          this.$message.success('编辑成功')
          this.handleCloses()
        })
      }
    },
    handleCloses() {
      this.info = {
        name: '',
        phone: '',
        sex: '',
        certificateType: '01',
        idCard: '',
        birthday: '',
        userId: ''
      }
      this.$router.replace({
        path: '/h5/newlyaddedlist',
        query: {
          refresh: new Date().getTime(),
        },
      })
    },
  },
  created() {
    if (this.$route.query.index == 2) {
      let data = JSON.parse(this.$route.query.data)
      console.log(data)
      this.info = {
        name: data.name,
        phone: data.phone,
        sex: data.sex,
        certificateType: data.certificateType,
        idCard: data.idCard,
        birthday: data.birthday,
        userId: data.userId,
        id: data.id,
      }
    }
  },
  mounted() {
    this.getIdCardType()
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
}
</script>

<style lang='scss' scoped>
.top {
  display: flex;
  justify-content: space-between;
  margin: 20PX 40PX 0;

  .titlebox {
    display: flex;
    align-items: center;

    .xian {
      width: 5PX;
      height: 15PX;
      background: #3E79CB;
      border-radius: 3PX;
    }

    .title {
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 15PX;
      margin-left: 10PX;
      color: #353333;
      background: linear-gradient(150deg, #2F6BBF 0.146484375%, #7FB5FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.inputbox {
  display: flex;
  align-items: center;
  height: 41PX;
  margin: 24PX 35PX 0;
  background: #F1F6FF;
  border-radius: 50PX;

  img {
    width: 10PX;
    height: 16PX;
    margin-left: 16PX;
  }

  p {
    font-size: 12PX;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    line-height: 19PX;
    flex-shrink: 0;
    margin-left: 9PX;
  }

  span {
    font-size: 13PX;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #E90709;
    flex-shrink: 0;
    margin-left: 4PX;
  }

  ::v-deep .el-input__inner {
    border: none;
    background: #F1F6FF;
    border-radius: 50PX;
    padding-left: 15PX;
  }

  .inputboxbut {
    flex-shrink: 0;
    margin-right: 11PX;
    padding: 5PX 10PX;
    font-size: 12PX;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 19PX;
    background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
    border-radius: 50PX;
  }

  .but {
    width: 100PX;
    height: 31PX;
    border-radius: 30PX;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(0deg, #1F6EEE, #60B8FF);
    flex-shrink: 0;
    margin-right: 5PX;
  }

  ::v-deep .el-select .el-input,
  ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  ::v-deep input::-webkit-input-placeholder {
    color: #333333;
  }
}

::v-deep .ant-select-selection--single {
  width: 180PX;
}

::v-deep .ant-select-arrow {
  display: none !important;
}

::v-deep .el-input__icon {
  display: none;
}

.button {
  width: calc(100% - 70PX);
  display: flex;
  justify-content: center;
  margin: 40PX 40PX 0;

  .save {
    width: 100%;
    height: 40PX;
    background-image: linear-gradient(to bottom, #6FB2FF, #095FFF);
    font-size: 14PX;
    border-radius: 50PX;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>